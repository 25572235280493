import { DAILY_POSITIONS } from '@/shared/components/data-grid/data-grid-date-cell/data-grid-date-cell.constants';
import {
  type CampaignCommentTagType,
  type CampaignCommentType,
  CampaignDepositTypes,
  CampaignLogSources,
  type CampaignLogType,
  type CampaignProductPhraseType,
  type CampaignType,
  type CampaignWithDailystatsType,
  type ColumnHeader,
  type PoolPhraseType,
  type ProductCatalogType,
  type ProductWithStatsType,
  campaignStatusSortComparator,
  getCampaignStatusName,
  getCampaignTypeName,
  getDailystatToday,
  getLastDays,
} from '@/shared/contract';
import {
  dateSortComparator,
  formatDate,
  formatNumber,
  getMoscowDateWithTime,
  numberSortComparator,
} from '@/shared/lib';
import { type GridSortDirection } from '@mui/x-data-grid/models';
import { type GridAlignment, type GridCellParams, type GridColDef } from '@mui/x-data-grid-premium';

import { isEmail } from './functions/is-email.function';
import { getCampaignLogTypeName } from '../contract/functions/get-campaign-log-type-name.function';

export const NO_RESULT = '–';
export const ALL_PERIOD = 'За выбранный период';
export const FORMATTER_LANG = 'ru-RU';
export const MAX_MAX_CPC = 100;
export const MAX_MIN_CTR = 50;
export const MIN_MAX_CPC = 1;
export const MIN_MAX_CPM = 150;
export const MAX_MAX_CPM = 15000;
export const MIN_MAX_CPM_AUTO = 125;
export const MIN_MAX_VIEW_COUNT = 50;
export const MIN_MIN_CTR = 0.1;
export const MIN_MIN_POSITION = 1;
export const MIN_BUDGET = 1000;
export const MIN_BUDGET_AUTO_DEPOSIT = 100;
export const CHANGE_BUDGET = 100;
export const MIN_TARGET_PLACE = 1;
export const MAX_PLUS_FIXED = 100;
export const MAX_MINUS_SEARCH = 1000;

export const POSITIONS_NUMBER_VALUE = new Map<number, number>([
  [99991, 1949],
  [99992, 2949],
  [99993, 3949],
  [99995, 5949],
  [99996, 6949],
  [99997, 6949],
  [99998, 6949],
  [99999, 6949],
]);

export const EMAIL_VALIDATION = {
  required: 'E-mail не должен быть пустым',
  validate: (val: string) => isEmail(val) || 'Не валидный e-mail',
};

export const PHONE_VALIDATION = {
  required: 'Телефон не должен быть пустым',
};

export const PASSWORD_VALIDATION = {
  required: 'Пароль не должен быть пустым',
  minLength: {
    value: 6,
    message: 'Пароль не должен быть меньше 6 символов',
  },
};

// Общие настройки для выравнивания колонок
export const ALIGN_COLUMNS = {
  headerAlign: 'left' as GridAlignment,
  align: 'left' as GridAlignment,
};

/** **************************************
 * Раздел:  products-stats.constants
 *          PRODUCTS_COLUMNS
 ****************************************/

// Функция для создания колонки с именем товара
export const nameColumn = (header: ColumnHeader): GridColDef => ({
  field: 'name',
  headerName: header,
  type: 'string',
  width: 110,
  minWidth: 80,
  hideable: false,
  valueGetter: (params) => (isString(params.value) ? params.value.replace(/ё/g, 'е') : NO_RESULT),
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с артикулом
export const articleColumn = (
  header: ColumnHeader,
  description?: string,
  options: { groupable?: boolean; minWidth?: number } = {},
): GridColDef => {
  const { groupable, minWidth } = options;

  return {
    field: 'article',
    headerName: header,
    type: 'string',
    width: 230,
    groupable,
    minWidth,
    description,
    valueGetter: (params: { row: ProductWithStatsType }) => params.row.article ?? 0,
    valueFormatter: (params: { value: number }) => (params.value ? params.value : ''),
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки с брендом
export const brandColumn = (header: ColumnHeader): GridColDef => ({
  field: 'brandName',
  headerName: header,
  type: 'string',
  width: 80,
  valueFormatter: (params: { value: string }) => (params.value ? params.value : ''),
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с предметом
export const subjectNameColumn = (header: ColumnHeader): GridColDef => ({
  field: 'subjectName',
  headerName: header,
  type: 'string',
  width: 80,
  valueGetter: (params: { row: ProductWithStatsType }) => params.row.subjectName ?? '',
  valueFormatter: (params: { value: string }) => (params.value ? params.value : ''),
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с ценой
export const priceColumn = (header: ColumnHeader): GridColDef => ({
  field: 'sppPrice',
  headerName: header,
  type: 'number',
  width: 80,
  valueGetter: (params: { row: ProductWithStatsType }) => params.row.sppPrice ?? 0,
  valueFormatter: (params: { value: number }) => (params.value ? formatNumber(params.value) : ''),
  sortComparator: numberSortComparator,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с рейтингом
export const ratingColumn = (header: ColumnHeader, description: string): GridColDef => ({
  field: 'rating',
  headerName: header,
  type: 'number',
  width: 80,
  sortComparator: numberSortComparator,
  valueFormatter: (params: { value: number }) => (params.value ? formatNumber(params.value) : ''),
  description,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с количеством показов
export const viewCountColumn = (
  header: ColumnHeader,
  description: string,
  options: {
    sortingOrder?: GridSortDirection[];
    flex?: number;
    groupable?: boolean;
  } = {},
  field = 'viewCount',
): GridColDef => {
  const { sortingOrder, flex, groupable } = options;

  return {
    field,
    headerName: header,
    type: 'number',
    width: 80,
    sortingOrder,
    minWidth: 80,
    flex,
    groupable,
    valueGetter: (params: { value: number }) => params.value ?? '',
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const viewCountAllColumn = (
  header: ColumnHeader,
  description: string,
  options: {
    sortingOrder?: GridSortDirection[];
    flex?: number;
    groupable?: boolean;
  } = {},
): GridColDef => {
  const { sortingOrder, flex, groupable } = options;

  return {
    field: 'viewCountAll',
    headerName: header,
    type: 'number',
    width: 80,
    sortingOrder,
    minWidth: 80,
    flex,
    groupable,
    valueGetter: (params: { value: number }) => params.value ?? '',
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const clickCountColumn = (
  header: ColumnHeader,
  description: string,
  options: {
    sortingOrder?: GridSortDirection[];
    groupable?: boolean;
  } = {},
  field = 'clickCount',
): GridColDef => {
  const { sortingOrder, groupable } = options;

  return {
    field,
    headerName: header,
    type: 'number',
    width: 80,
    sortingOrder,
    minWidth: 80,
    groupable,
    valueGetter: (params: { value: number }) => params.value ?? '',
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const clickCountJamColumn = (
  header: ColumnHeader,
  description: string,
  options: {
    sortingOrder?: GridSortDirection[];
    groupable?: boolean;
  } = {},
): GridColDef => {
  const { sortingOrder, groupable } = options;

  return {
    field: 'clickCountJam',
    headerName: header,
    type: 'number',
    width: 80,
    sortingOrder,
    minWidth: 80,
    groupable,
    valueGetter: (params: { value: number }) => params.value ?? '',
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const clickCountAllColumn = (
  header: ColumnHeader,
  description: string,
  options: {
    sortingOrder?: GridSortDirection[];
    groupable?: boolean;
  } = {},
): GridColDef => {
  const { sortingOrder, groupable } = options;

  return {
    field: 'clickCountAll',
    headerName: header,
    type: 'number',
    width: 80,
    sortingOrder,
    minWidth: 80,
    groupable,
    valueGetter: (params: { value: number }) => params.value ?? '',
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки с CTR
export const ctrColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
  field = 'ctr',
): GridColDef => {
  const { groupable } = options;

  return {
    field,
    headerName: header,
    type: 'number',
    width: 80,
    minWidth: 80,
    groupable,
    valueGetter: (params: { value: number }) => params.value ?? '',
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value, 2) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const ctrAllColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef => {
  const { groupable } = options;

  return {
    field: 'ctrAll',
    headerName: header,
    type: 'number',
    width: 80,
    minWidth: 80,
    groupable,
    valueGetter: (params: { value: number }) => params.value ?? '',
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value, 2) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки с CPC
export const cpcColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
  field = 'cpc',
): GridColDef => {
  const { groupable } = options;

  return {
    field,
    headerName: header,
    type: 'number',
    width: 80,
    minWidth: 80,
    groupable,
    valueGetter: (params: { value: number }) => params.value ?? '',
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const cpcJamColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef => {
  const { groupable } = options;

  return {
    field: 'cpcJam',
    headerName: header,
    type: 'number',
    width: 80,
    minWidth: 80,
    groupable,
    valueGetter: (params: { value: number }) => params.value ?? '',
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки с CPM
export const cpmColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
  field = 'cpm',
): GridColDef => {
  const { groupable } = options;

  return {
    field,
    headerName: header,
    type: 'number',
    width: 80,
    minWidth: 80,
    groupable,
    valueGetter: (params: { value: number }) => params.value ?? '',
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки с CR
export const crColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef => {
  const { groupable } = options;

  return {
    field: 'cr',
    headerName: header,
    type: 'number',
    width: 80,
    groupable,
    valueGetter: (params: { value: number }) => params.value ?? '',
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value, 2) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const crSkuColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef => {
  const { groupable } = options;

  return {
    field: 'crSku',
    headerName: header,
    type: 'number',
    width: 80,
    groupable,
    valueGetter: (params: { value: number }) => params.value ?? '',
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value, 2) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки с количеством добавленных товаров в корзину
export const cartCountColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef => {
  const { groupable } = options;

  return {
    field: 'cartCount',
    headerName: header,
    type: 'number',
    width: 110,
    groupable,
    valueGetter: (params: { value: number }) => params.value ?? '',
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value, 2) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const cartCountJamColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef => {
  const { groupable } = options;

  return {
    field: 'cartCountJam',
    headerName: header,
    type: 'number',
    width: 110,
    groupable,
    valueGetter: (params: { value: number }) => params.value ?? '',
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value, 2) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const historyTimestampColumn = (
  header: ColumnHeader,
  options: {
    width?: number;
    hideable?: boolean;
    sortingOrder?: GridSortDirection[];
    groupable?: boolean;
  } = {},
): GridColDef => {
  const { width = 110, hideable = false, sortingOrder, groupable } = options;

  return {
    field: 'timestamp',
    headerName: header,
    type: 'string',
    width,
    hideable,
    sortingOrder,
    groupable,
    // eslint-disable-next-line no-negated-condition
    valueFormatter: (params) => getMoscowDateWithTime(new Date(params.value)),
    sortComparator: dateSortComparator,
    ...ALIGN_COLUMNS,
  };
};

export const historySourceColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef<CampaignLogType> => {
  const { groupable } = options;

  return {
    field: 'source',
    headerName: header,
    type: 'string',
    width: 110,
    groupable,
    valueGetter: (params) => params.row.source,
    valueFormatter: (params) => (params.value === CampaignLogSources.MANUAL ? 'Ручное' : 'Авто'),
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const historyCpmOldColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef<CampaignLogType> => {
  const { groupable } = options;

  return {
    field: 'oldCpm',
    headerName: header,
    type: 'number',
    width: 110,
    groupable,
    valueGetter: (params) => params.row.payload.oldCpm ?? 0,
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const historyCpmNewColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef<CampaignLogType> => {
  const { groupable } = options;

  return {
    field: 'newCpm',
    headerName: header,
    type: 'number',
    width: 110,
    groupable,
    valueGetter: (params) => params.row.payload.newCpm ?? 0,
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const historyCpmDiffColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef<CampaignLogType> => {
  const { groupable } = options;

  return {
    field: 'cpmDiff',
    headerName: header,
    type: 'number',
    width: 110,
    groupable,
    valueGetter: (params) =>
      params.row.payload.newCpm && params.row.payload.oldCpm
        ? +params.row.payload.newCpm - +params.row.payload.oldCpm
        : 0,
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const historyBoostPositionColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef<CampaignLogType> => {
  const { groupable } = options;

  return {
    field: 'boostPosition',
    headerName: header,
    type: 'number',
    width: 110,
    groupable,
    valueGetter: (params) => params.row.payload?.boostPosition,
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const historyTypeColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef<CampaignLogType> => {
  const { groupable } = options;

  return {
    field: 'type',
    headerName: header,
    type: 'string',
    width: 110,
    groupable,
    valueGetter: (params) => params.row.type ?? '',
    valueFormatter: (params) => (params.value ? getCampaignLogTypeName(params.value) : NO_RESULT),
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const historyLogColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef<CampaignLogType> => {
  const { groupable } = options;

  return {
    field: 'log',
    headerName: header,
    type: 'string',
    width: 110,
    groupable,
    valueGetter: (params: { value: string }) => params.value ?? '',
    valueFormatter: (params: { value: string }) => (params.value ? params.value : NO_RESULT),
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const historyBudgetSumColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef<CampaignLogType> => {
  const { groupable } = options;

  return {
    field: 'sum',
    headerName: header,
    type: 'number',
    width: 110,
    groupable,
    valueGetter: (params) => params.row.payload.addBudget,
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const historyBudgetTypeColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef<CampaignLogType> => {
  const { groupable } = options;

  return {
    field: 'budgetType',
    headerName: header,
    type: 'string',
    width: 110,
    groupable,
    valueGetter: (params) => params.row.source,
    valueFormatter: (params) => (params.value === CampaignLogSources.MANUAL ? 'Ручное' : 'Авто'),
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const historyBudgetDepositeTypeColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef<CampaignLogType> => {
  const { groupable } = options;

  return {
    field: 'depositType',
    headerName: header,
    type: 'string',
    width: 110,
    groupable,
    valueGetter: (params) => params.row.payload.depositType,
    valueFormatter: (params) => (params.value === CampaignDepositTypes.BALANCE ? 'Баланс' : 'Счет'),
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const historyActionColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef<CampaignLogType> => {
  const { groupable } = options;

  return {
    field: 'action',
    headerName: header,
    type: 'string',
    width: 300,
    groupable,
    valueGetter: (params: { value: string }) => params.value ?? '',
    valueFormatter: (params: { value: string }) => (params.value ? params.value : NO_RESULT),
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const historySettingsChangeColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef<CampaignLogType> => {
  const { groupable } = options;

  return {
    field: 'settingsChange',
    headerName: header,
    type: 'string',
    width: 340,
    groupable,
    valueGetter: (params: { value: string }) => params.value ?? '',
    valueFormatter: (params: { value: string }) => (params.value ? params.value : NO_RESULT),
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const notesCommentedAtColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef<CampaignCommentType> => {
  const { groupable } = options;

  return {
    field: 'commentedAt',
    headerName: header,
    type: 'string',
    width: 130,
    groupable,
    valueGetter: (params: { value: string }) =>
      params.value ? new Date(params.value).toLocaleDateString() : '',
    valueFormatter: (params: { value: string }) => (params.value ? params.value : ''),
    sortComparator: dateSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const notesCampaignColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef<CampaignLogType> => {
  const { groupable } = options;

  return {
    field: 'campaignWbId',
    headerName: header,
    type: 'string',
    width: 200,
    groupable,
    valueGetter: (params: { value: string }) => params.value ?? '',
    valueFormatter: (params: { value: string }) => (params.value ? params.value : NO_RESULT),
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const notesTagIdsColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef<CampaignCommentType> => {
  const { groupable } = options;

  return {
    field: 'tagIds',
    headerName: header,
    type: 'array',
    width: 200,
    groupable,
    valueGetter: (params): CampaignCommentTagType => (params.value as CampaignCommentTagType) || [],
    valueFormatter: (params) =>
      params.value && Array.isArray(params.value)
        ? params.value.map((tag: CampaignCommentTagType) => tag.name).join(', ')
        : NO_RESULT,
    sortComparator: (a, b) => a.length - b.length,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const notesCommentColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean } = {},
): GridColDef<CampaignLogType> => {
  const { groupable } = options;

  return {
    field: 'text',
    headerName: header,
    type: 'string',
    groupable,
    valueGetter: (params: { value: string }) => params.value ?? '',
    valueFormatter: (params: { value: string }) => (params.value ? params.value : NO_RESULT),
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки с количеством заказов
export const orderCountColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean; round?: number } = {},
  field: string = 'orderCount',
): GridColDef => {
  const { groupable, round = 2 } = options;

  return {
    field,
    headerName: header,
    type: 'number',
    width: 80,
    groupable,
    valueGetter: (params: { value: number }) => params.value ?? '',
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value, round) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const orderCountJamColumn = (
  header: ColumnHeader,
  description: string,
  options: { groupable?: boolean; round?: number } = {},
): GridColDef => {
  const { groupable, round = 2 } = options;

  return {
    field: 'orderCountJam',
    headerName: header,
    type: 'number',
    width: 80,
    groupable,
    valueGetter: (params: { value: number }) => params.value ?? '',
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value, round) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки с суммой заказов
export const orderSumColumn = (
  header: ColumnHeader,
  description: string,
  options: { sortingOrder?: GridSortDirection[]; groupable?: boolean } = {},
  field: string = 'orderSum',
): GridColDef => {
  const { sortingOrder, groupable } = options;

  return {
    field,
    headerName: header,
    type: 'number',
    width: 80,
    sortingOrder,
    groupable,
    valueGetter: (params: { value: number }) => params.value ?? '',
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value, 2) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки с количеством заказанных товаров в штуках
export const goodsCountColumn = (
  header: ColumnHeader,
  description: string,
  options: { width?: number; groupable?: boolean } = {},
): GridColDef => {
  const { width, groupable } = options;

  return {
    field: 'goodsCount',
    headerName: header,
    type: 'number',
    width,
    groupable,
    valueGetter: (params: { value: number }) => params.value ?? '',
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value, 2) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки с ДРР (доля рекламных расходов)
export const drrColumn = (header: ColumnHeader, description: string): GridColDef => ({
  field: 'drr',
  headerName: header,
  type: 'number',
  width: 80,
  valueGetter: (params: { value: number }) => params.value ?? '',
  valueFormatter: (params: { value: number }) =>
    params.value ? formatNumber(params.value) : NO_RESULT,
  sortComparator: numberSortComparator,
  description,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с CPO (стоимость заказа)
export const cpoColumn = (header: ColumnHeader, description: string): GridColDef => ({
  field: 'cpo',
  headerName: header,
  type: 'number',
  width: 80,
  valueGetter: (params: { value: number }) => params.value ?? '',
  valueFormatter: (params: { value: number }) =>
    params.value ? formatNumber(params.value) : NO_RESULT,
  sortComparator: numberSortComparator,
  description,
  ...ALIGN_COLUMNS,
});

export const cpoJamColumn = (header: ColumnHeader, description: string): GridColDef => ({
  field: 'cpoJam',
  headerName: header,
  type: 'number',
  width: 80,
  valueGetter: (params: { value: number }) => params.value ?? '',
  valueFormatter: (params: { value: number }) =>
    params.value ? formatNumber(params.value) : NO_RESULT,
  sortComparator: numberSortComparator,
  description,
  ...ALIGN_COLUMNS,
});

export const cpoSkuColumn = (header: ColumnHeader, description: string): GridColDef => ({
  field: 'cpoSku',
  headerName: header,
  type: 'number',
  width: 80,
  valueGetter: (params: { value: number }) => params.value ?? '',
  valueFormatter: (params: { value: number }) =>
    params.value ? formatNumber(params.value) : NO_RESULT,
  sortComparator: numberSortComparator,
  description,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с затратами
export const spentColumn = (
  header: ColumnHeader,
  description: string,
  options: { width?: number; sortingOrder?: GridSortDirection[]; groupable?: boolean } = {},
  field = 'spent',
): GridColDef => {
  const { width, sortingOrder, groupable } = options;

  return {
    field,
    headerName: header,
    type: 'number',
    width,
    sortingOrder,
    minWidth: 80,
    groupable,
    valueGetter: (params: { value: number }) => params.value ?? '',
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

export const spentAllColumn = (
  header: ColumnHeader,
  description: string,
  options: { width?: number; sortingOrder?: GridSortDirection[]; groupable?: boolean } = {},
): GridColDef => {
  const { width, sortingOrder, groupable } = options;

  return {
    field: 'spentAll',
    headerName: header,
    type: 'number',
    width,
    sortingOrder,
    minWidth: 80,
    groupable,
    valueGetter: (params: { value: number }) => params.value ?? '',
    valueFormatter: (params: { value: number }) =>
      params.value ? formatNumber(params.value) : NO_RESULT,
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

/** **************************************
 * Раздел:  products-stats.constants
 *          FULL_SKU_COLUMNS
 ****************************************/

// Функция для создания колонки с количеством переходов в карточку товара
export const visitCountColumn = (header: ColumnHeader, description: string): GridColDef => ({
  field: 'visitCount',
  headerName: header,
  type: 'number',
  width: 80,
  valueGetter: (params: { value: number }) => params.value ?? 0,
  valueFormatter: (params: { value: number }) =>
    params.value ? formatNumber(params.value) : NO_RESULT,
  sortComparator: numberSortComparator,
  description,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с количеством товаров, добавленных в корзину
export const leadCountColumn = (header: ColumnHeader, description: string): GridColDef => ({
  field: 'leadCount',
  headerName: header,
  type: 'number',
  width: 110,
  valueGetter: (params: { value: number }) => params.value ?? 0,
  valueFormatter: (params: { value: number }) =>
    params.value ? formatNumber(params.value) : NO_RESULT,
  sortComparator: numberSortComparator,
  description,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с CRL (конверсия из клика в корзину)
export const crlColumn = (header: ColumnHeader, description: string): GridColDef => ({
  field: 'crl',
  headerName: header,
  type: 'number',
  width: 80,
  valueGetter: (params: { value: number }) => params.value ?? '',
  valueFormatter: (params: { value: number }) =>
    params.value ? formatNumber(params.value, 2) : NO_RESULT,
  sortComparator: numberSortComparator,
  description,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с CRLSku (конверсия из клика в корзину)
export const crlSkuColumn = (header: ColumnHeader, description: string): GridColDef => ({
  field: 'crlSku',
  headerName: header,
  type: 'number',
  width: 80,
  valueGetter: (params: { value: number }) => params.value ?? 0,
  valueFormatter: (params: { value: number }) =>
    params.value ? formatNumber(params.value, 2) : NO_RESULT,
  sortComparator: numberSortComparator,
  description,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с количеством заказов в шт
export const orderCountSkuColumn = (header: ColumnHeader, description: string): GridColDef => ({
  field: 'orderCountSku',
  headerName: header,
  type: 'number',
  width: 100,
  valueGetter: (params: { value: number }) => params.value ?? 0,
  valueFormatter: (params: { value: number }) =>
    params.value ? formatNumber(params.value) : NO_RESULT,
  sortComparator: numberSortComparator,
  description,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с суммой заказов в рублях
export const orderSumSkuColumn = (header: ColumnHeader, description: string): GridColDef => ({
  field: 'orderSumSku',
  headerName: header,
  type: 'number',
  width: 90,
  valueGetter: (params: { value: number }) => params.value ?? 0,
  valueFormatter: (params: { value: number }) =>
    params.value ? formatNumber(params.value) : NO_RESULT,
  sortComparator: numberSortComparator,
  description,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с CRO (конверсия из корзины в заказ)
export const croColumn = (header: ColumnHeader, description: string): GridColDef => ({
  field: 'cro',
  headerName: header,
  type: 'number',
  width: 80,
  valueGetter: (params: { value: number }) => params.value ?? '',
  valueFormatter: (params) => (params.value ? formatNumber(params.value, 2) : NO_RESULT),
  sortComparator: numberSortComparator,
  description,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с CROSku (конверсия из корзины в заказ)
export const croSkuColumn = (header: ColumnHeader, description: string): GridColDef => ({
  field: 'croSku',
  headerName: header,
  type: 'number',
  width: 80,
  valueGetter: (params: { value: number }) => params.value ?? 0,
  valueFormatter: (params) => (params.value ? formatNumber(params.value, 2) : NO_RESULT),
  sortComparator: numberSortComparator,
  description,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с CPL (затраты на добавление в корзину)
export const cplColumn = (header: ColumnHeader, description: string): GridColDef => ({
  field: 'cpl',
  headerName: header,
  type: 'number',
  width: 80,
  valueGetter: (params: { value: number }) => params.value ?? '',
  valueFormatter: (params: { value: number }) =>
    params.value ? formatNumber(params.value) : NO_RESULT,
  sortComparator: numberSortComparator,
  description,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с CPLsku (затраты на добавление в корзину)
export const cplSkuColumn = (header: ColumnHeader, description: string): GridColDef => ({
  field: 'cplSku',
  headerName: header,
  type: 'number',
  width: 80,
  // valueGetter: (params) => params.row.cpl ?? 0,
  valueFormatter: (params: { value: number }) =>
    params.value ? formatNumber(params.value) : NO_RESULT,
  sortComparator: numberSortComparator,
  description,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с количеством выкупленных товаров в штуках
export const sellCountColumn = (header: ColumnHeader, description: string): GridColDef => ({
  field: 'sellCount',
  headerName: header,
  type: 'number',
  width: 100,
  valueGetter: (params: { value: number }) => params.value ?? 0,
  valueFormatter: (params: { value: number }) =>
    params.value ? formatNumber(params.value) : NO_RESULT,
  sortComparator: numberSortComparator,
  description,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с ДРР (доля рекламных расходов)
export const drrSkuColumn = (header: ColumnHeader, description: string): GridColDef => ({
  field: 'drrSku',
  headerName: header,
  type: 'number',
  width: 80,
  // valueGetter: (params) => params.row.drr ?? 0,
  valueFormatter: (params: { value: number }) =>
    params.value ? formatNumber(params.value) : NO_RESULT,
  sortComparator: numberSortComparator,
  description,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с суммой продаж в рублях
export const sellSumColumn = (header: ColumnHeader, description: string): GridColDef => ({
  field: 'sellSum',
  headerName: header,
  type: 'number',
  width: 90,
  valueGetter: (params: { value: number }) => params.value ?? 0,
  valueFormatter: (params: { value: number }) =>
    params.value ? formatNumber(params.value) : NO_RESULT,
  sortComparator: numberSortComparator,
  description,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с коэффициентом выкупа
export const crsColumn = (header: ColumnHeader, description: string): GridColDef => ({
  field: 'crs',
  headerName: header,
  type: 'number',
  width: 90,
  valueGetter: (params: { value: number }) => params.value ?? 0,
  valueFormatter: (params: { value: number }) =>
    params.value ? formatNumber(params.value, 2) : NO_RESULT,
  sortComparator: numberSortComparator,
  description,
  ...ALIGN_COLUMNS,
});

/** **************************************
 * Раздел:  products-stats.constants
 *          CAMPAIGNS_COLUMNS
 ****************************************/

// Функция для создания колонки с названием товара
export const productNameColumn = (header: ColumnHeader): GridColDef => ({
  field: 'productName',
  headerName: header,
  type: 'string',
  width: 110,
  hideable: false,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с артикулом кампании
export const campaignArticleColumn = (header: ColumnHeader): GridColDef => ({
  field: 'campaignArticle',
  headerName: header,
  type: 'number',
  width: 80,
  valueFormatter: (params: { value: number }) => (params.value ? `${params.value}` : ''),
  sortComparator: numberSortComparator,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с ID кампании
export const campaignWbIdColumn = (header: ColumnHeader): GridColDef => ({
  field: 'campaignWbId',
  headerName: header,
  type: 'number',
  width: 120,
  valueGetter: (params: { row: CampaignType }) => params.row.wbId ?? 0,
  valueFormatter: (params: { value: number }) => (params.value ? `${params.value}` : ''),
  sortComparator: numberSortComparator,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки со статусом
export const statusColumn = (
  header: ColumnHeader,
  options: {
    width?: number;
    type?: string;
    hideable?: boolean;
  } = {},
): GridColDef => {
  const { width = 80, type = 'number', hideable } = options;

  return {
    field: 'status',
    headerName: header,
    type,
    width,
    minWidth: 80,
    hideable,
    filterable: false,
    valueFormatter: (params) => (isString(params.value) ? getCampaignStatusName(params.value) : ''),
    sortComparator: campaignStatusSortComparator,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки с типом кампании
export const typeColumn = (header: ColumnHeader): GridColDef => ({
  field: 'type',
  headerName: header,
  type: 'string',
  width: 80,
  valueGetter: (params) => (isString(params.value) ? getCampaignTypeName(params.value) : ''),
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с биддером
export const bidManagerColumn = (
  header: ColumnHeader,
  description: string,
  options: { width?: number; type?: string; hideable?: boolean } = {},
): GridColDef => {
  const { width = 80, type = 'number', hideable } = options;

  return {
    field: 'useBidManager',
    headerName: header,
    type,
    width,
    minWidth: 80,
    description,
    hideable,
    filterable: false,
    valueGetter: (params) => Boolean(params.value),
    ...ALIGN_COLUMNS,
  };
};

export const autoDepositeColumn = (
  header: ColumnHeader,
  description: string,
  options: { width?: number; type?: string; hideable?: boolean } = {},
): GridColDef => {
  const { width = 80, type = 'number', hideable } = options;

  return {
    field: 'autoDeposite',
    headerName: header,
    type,
    width,
    minWidth: 80,
    description,
    hideable,
    filterable: false,
    valueGetter: (params) => Boolean(params.value),
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки с названием кампании
export const campaignNameColumn = (header: ColumnHeader, field = 'campaignName'): GridColDef => ({
  field,
  headerName: header,
  type: 'number',
  width: 180,
  ...ALIGN_COLUMNS,
});

export const campaignNameDynamicColumn = (campaign: CampaignWithDailystatsType): GridColDef => ({
  field: campaign.wbId.toString(),
  headerName: campaign.name,
  type: 'string',
  width: 180,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки с бюджетом кампании
export const budgetColumn = (
  header: ColumnHeader,
  options: { width?: number; sortingOrder?: [] } = {},
): GridColDef => {
  const { width, sortingOrder } = options;

  return {
    field: 'budget',
    headerName: header,
    type: 'number',
    width,
    valueGetter: (params: { value: number }) => params.value,
    valueFormatter: (params: { value: number }) => (params.value ? formatNumber(params.value) : ''),
    sortComparator: numberSortComparator,
    sortingOrder,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки с датой добавления
export const startedAtColumn = (header: ColumnHeader): GridColDef => ({
  field: 'issuedAt',
  headerName: header,
  type: 'number',
  width: 130,
  sortComparator: dateSortComparator,
  valueGetter: (params) => (params.value ? formatDate(params.value) : NO_RESULT),
  ...ALIGN_COLUMNS,
});

/** **************************************
 * Раздел:  product-compaings.constants
 ****************************************/

// Функция для создания колонки с фиксироваными фразами
export const fixedColumn = (
  header: ColumnHeader,
  options: {
    width?: number;
    hideable?: boolean;
    type?: string;
  } = {},
): GridColDef => {
  const { width = 70, hideable = false, type = 'boolean' } = options;

  return {
    field: 'useFixed',
    headerName: header,
    type,
    width,
    minWidth: 70,
    hideable,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки wbId
export const wbIdColumn = (
  header: ColumnHeader,
  options: {
    width?: number;
    type?: string;
    sortingOrder?: [];
  } = {},
): GridColDef => {
  const { width = 95, type = 'string', sortingOrder = [] } = options;

  return {
    field: 'wbId',
    headerName: header,
    type,
    width,
    minWidth: 80,
    valueGetter: (params: GridCellParams<any, number>) => (params.value ? params.value : NO_RESULT),
    sortingOrder,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки с частотой запроса
export const requestCountColumn = (
  header: ColumnHeader,
  options: {
    width?: number;
    type?: string;
    sortingOrder?: GridSortDirection[];
  } = {},
): GridColDef => {
  const { width, type = 'number', sortingOrder } = options;

  return {
    field: 'requestCount',
    headerName: header,
    type,
    width,
    minWidth: 80,
    valueGetter: (params: GridCellParams) => params.value ?? '',
    valueFormatter: (params) => (params.value ? formatNumber(params.value) : NO_RESULT),
    sortComparator: numberSortComparator,
    sortingOrder,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки "Дата создания"
export const issuedAtColumn = (
  header: ColumnHeader,
  options: {
    width?: number;
  } = {},
): GridColDef => {
  const { width = 110 } = options;

  return {
    field: 'issuedAt',
    headerName: header,
    type: 'string',
    width,
    sortComparator: dateSortComparator,
    valueGetter: (params) => (params.value ? formatDate(params.value) : NO_RESULT),
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки "Приостановлено"
export const stoppedAtColumn = (
  header: ColumnHeader,
  options: {
    width?: number;
  } = {},
): GridColDef => {
  const { width = 110 } = options;

  return {
    field: 'stoppedAt',
    headerName: header,
    type: 'string',
    width,
    sortComparator: dateSortComparator,
    valueGetter: (params) => (params.value ? formatDate(params.value) : NO_RESULT),
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки "wbID"
export const campaignIdColumn = (
  header: ColumnHeader,
  options: {
    width?: number;
    sortingOrder?: GridSortDirection[];
  } = {},
): GridColDef => {
  const { width = 95, sortingOrder } = options;

  return {
    field: 'campaignWbId',
    headerName: header,
    type: 'string',
    minWidth: 80,
    width,
    valueGetter: (params: GridCellParams<any, number>) => (params.value ? params.value : NO_RESULT),
    sortingOrder,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки "Дата"
export const dateColumn: {
  (
    header: ColumnHeader,
    options: {
      width?: number;
      hideable?: boolean;
      sortingOrder?: GridSortDirection[];
      minWidth: number;
      groupable?: boolean;
    },
  ): GridColDef;
  (
    header: ColumnHeader,
    options?: {
      width?: number;
      hideable?: boolean;
      sortingOrder?: GridSortDirection[];
      groupable?: boolean;
    },
  ): GridColDef;
} = (header: ColumnHeader, options: any = {}): GridColDef => {
  const { width = 110, hideable = false, minWidth, sortingOrder, groupable } = options;

  return {
    field: 'date',
    headerName: header,
    type: 'string',
    width,
    ...(minWidth ? { minWidth } : {}),
    hideable,
    sortingOrder,
    groupable,
    valueFormatter: (params) =>
      params.value === ALL_PERIOD ? (params.value as string) : formatDate(params.value),
    sortComparator: dateSortComparator,
    ...ALIGN_COLUMNS,
  };
};

/** **************************************
 * Раздел:  campaign-stats.constants
 ****************************************/

// Функция для создания колонки "Фразы"
export const keywordColumn = (
  header: ColumnHeader,
  options: { width?: number } = {},
): GridColDef => {
  const { width } = options;

  return {
    field: 'keyword',
    headerName: header,
    type: 'string',
    hideable: false,
    width,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки "Частота"
export const requestCounterColumn = (
  header: ColumnHeader,
  options: {
    sortingOrder?: GridSortDirection[];
    sortable?: boolean;
  } = {},
): GridColDef => {
  const { sortingOrder, sortable } = options;

  return {
    field: 'requestCount',
    headerName: header,
    type: 'number',
    sortable,
    sortingOrder,
    minWidth: 80,
    valueGetter: (params: { row: CampaignProductPhraseType }) => params.row?.requestCount ?? 0,
    valueFormatter: (params) => (params.value ? formatNumber(params.value) : NO_RESULT),
    sortComparator: numberSortComparator,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки "Приоритет"
export const priorityColumn = (
  header: ColumnHeader,
  options: {
    sortingOrder?: GridSortDirection[];
  } = {},
): GridColDef => {
  const { sortingOrder = ['asc', 'desc'] } = options;

  return {
    field: 'priority',
    headerName: header,
    type: 'number',
    sortingOrder,
    valueGetter: (params: { value: number }) => params.value ?? 0,
    valueFormatter: (params) => (params.value ? formatNumber(params.value) : NO_RESULT),
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки "Текущая стоимость за 1000 показов"
export const currentCpmColumn = (
  header: ColumnHeader,
  description: string,
  options: {
    sortingOrder?: GridSortDirection[];
  } = {},
  field = 'currentCpm',
): GridColDef => {
  const { sortingOrder } = options;

  return {
    field,
    headerName: header,
    type: 'number',
    sortingOrder,
    valueGetter: (params: { row: CampaignProductPhraseType }) => {
      const dailystat = getDailystatToday(params.row.dailystats);

      if (!dailystat?.cpm || !dailystat.position) {
        return 0;
      }

      return dailystat.cpm;
    },
    valueFormatter: (params) => (params.value ? formatNumber(params.value) : NO_RESULT),
    sortComparator: numberSortComparator,
    description,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки "Буст"
export const boostColumn = (
  header: ColumnHeader,
  options: {
    sortingOrder?: GridSortDirection[];
  } = {},
): GridColDef => {
  const { sortingOrder } = options;

  return {
    field: 'boost',
    headerName: header,
    type: 'number',
    sortingOrder,
    valueGetter: (params: { row: CampaignProductPhraseType }) => {
      const dailystat = getDailystatToday(params.row.dailystats);

      if (
        !dailystat?.cpm ||
        !dailystat.boostPosition ||
        !dailystat.position ||
        DAILY_POSITIONS.has(dailystat.boostPosition) ||
        DAILY_POSITIONS.has(dailystat.position)
      ) {
        return 0;
      }

      return dailystat.position - dailystat.boostPosition;
    },
    valueFormatter: (params) => (params.value ? formatNumber(params.value) : NO_RESULT),
    sortComparator: numberSortComparator,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки "PoolHash"
export const poolHashColumn = (header: ColumnHeader): GridColDef => ({
  field: 'poolHash',
  headerName: header,
  type: 'string',
});

// Функция для создания колонки "Пресет"
export const presetWbIdColumn = (header: ColumnHeader): GridColDef => ({
  field: 'presetWbId',
  headerName: header,
  type: 'number',
  valueFormatter: (params) => (params.value ? `${params.value}` : NO_RESULT),
  sortComparator: numberSortComparator,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки "Кластер"
export const normQueryColumn = (
  header: ColumnHeader,
  options: { sortingOrder?: GridSortDirection[] } = {},
): GridColDef => {
  const { sortingOrder } = options;

  return {
    field: 'normQuery',
    headerName: header,
    type: 'string',
    valueGetter: (params: { row: CampaignProductPhraseType }) =>
      params.row?.poolKeyword ?? params.row?.normQuery ?? NO_RESULT,
    sortComparator: numberSortComparator,
    valueFormatter: (params) => (params.value ? (params.value as string) : NO_RESULT),
    sortingOrder,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки "Изображения"
export const imageThumbUrlColumn = (
  header: ColumnHeader,
  options: { width?: number; filterable?: boolean } = {},
): GridColDef => {
  const { width = 60, filterable = false } = options;

  return {
    field: 'imageThumbUrl',
    headerName: header,
    type: 'string',
    filterable,
    maxWidth: 150,
    width,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки "Артикула"
export const anchorArticleColumn = (header: ColumnHeader): GridColDef => ({
  field: 'anchorArticle',
  headerName: header,
  type: 'string',
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки "Карточек"
export const productCountColumn = (
  header: ColumnHeader,
  options: { sortingOrder?: GridSortDirection[] } = {},
): GridColDef => {
  const { sortingOrder } = options;

  return {
    field: 'productCount',
    headerName: header,
    type: 'number',
    valueGetter: (params: { row: CampaignProductPhraseType }) =>
      params.row?.productCount ?? NO_RESULT,
    // valueFormatter: (params) => params.value ? formatNumber(params.value) : NO_RESULT,
    sortComparator: numberSortComparator,
    sortingOrder,
    minWidth: 90,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки "Кластера WB"
export const clusterKeywordColumn = (
  header: ColumnHeader,
  options: { sortingOrder?: GridSortDirection[] } = {},
): GridColDef => {
  const { sortingOrder } = options;

  return {
    field: 'clusterKeyword',
    headerName: header,
    type: 'string',
    valueGetter: (params: { row: CampaignProductPhraseType }) => params.row?.normQuery ?? NO_RESULT,
    sortingOrder,
    // flex: 1,
    minWidth: 80,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки "Кол-во фраз"
export const phraseCountColumn = (
  header: ColumnHeader,
  options: { sortingOrder?: GridSortDirection[]; flex?: number } = {},
): GridColDef => {
  const { sortingOrder, flex } = options;

  return {
    field: 'phraseCount',
    headerName: header,
    type: 'number',
    flex,
    valueGetter: (params: { row: PoolPhraseType }) => params.row?.phraseCount ?? '',
    valueFormatter: (params) => (params.value ? formatNumber(params.value) : NO_RESULT),
    sortComparator: numberSortComparator,
    sortingOrder,
    minWidth: 90,
    ...ALIGN_COLUMNS,
  };
};

// Функция для создания колонки "Топ"
export const topColumn = (header: ColumnHeader): GridColDef => ({
  field: 'top',
  headerName: header,
  type: 'number',
  valueGetter: ({ row }: { row: ProductCatalogType }) => {
    const positions = row?.dailystats.map((s: { position?: number }) => s.position || 0);
    const position = Math.min(...positions);

    return position ? formatNumber(position) : NO_RESULT;
  },
  sortComparator: numberSortComparator,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки "Дельта"
export const deltaColumn = (header: ColumnHeader): GridColDef => ({
  field: 'delta',
  headerName: header,
  type: 'number',
  valueGetter: ({ row }: { row: ProductCatalogType }) => {
    const getDateWithYear = (date: string): string => {
      const currentYear = new Date().getFullYear();
      const [day, month] = date.split('.');

      return `${currentYear}-${month}-${day}`;
    };

    const date = getLastDays(14, true)[14];
    const currentDateWithYear = getDateWithYear(date);
    const currentDailyPosition = row?.dailystats?.find(
      (dailyItem) => currentDateWithYear === dailyItem.date,
    )?.position;

    const prevDateWithYear = getDateWithYear(date);
    const prevDailyPosition = row?.dailystats?.find(
      (dailyItem) => prevDateWithYear === dailyItem.date,
    )?.position;

    return currentDailyPosition && prevDailyPosition
      ? formatNumber(currentDailyPosition - prevDailyPosition)
      : NO_RESULT;
  },
  sortComparator: numberSortComparator,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки "Дельта Топа"
export const deltaTopColumn = (header: ColumnHeader): GridColDef => ({
  field: 'deltaTop',
  headerName: header,
  type: 'number',
  valueGetter: ({ row }: { row: ProductCatalogType }) => {
    const getDateWithYear = (date: string): string => {
      const currentYear = new Date().getFullYear();
      const [day, month] = date.split('.');

      return `${currentYear}-${month}-${day}`;
    };

    const date = getLastDays(14, true)[14];
    const currentDateWithYear = getDateWithYear(date);
    const currentDailyPosition = row?.dailystats?.find(
      (dailyItem) => currentDateWithYear === dailyItem.date,
    )?.position;
    const positions = row?.dailystats?.map((s: { position?: number }) => s.position || 0);
    const top = Math.min(...positions);

    return top && currentDailyPosition ? formatNumber(top - currentDailyPosition) : NO_RESULT;
  },
  sortComparator: numberSortComparator,
  ...ALIGN_COLUMNS,
});

// Функция для создания колонки "Часы"
export const hourColumn = (header: ColumnHeader): GridColDef => ({
  field: 'hour',
  headerName: header,
  type: 'number',
  width: 110,
  sortingOrder: ['desc', 'asc'],
  sortComparator: numberSortComparator,
  groupable: false,
});

export const noteColumn = (
  header?: ColumnHeader,
  options: {
    width?: number;
    hideable?: boolean;
    sortingOrder?: GridSortDirection[];
    groupable?: boolean;
  } = {},
): GridColDef => {
  const { width = 25, hideable = false, sortingOrder, groupable } = options;

  return {
    field: 'note',
    headerName: header,
    type: 'string',
    width,
    hideable,
    sortingOrder,
    groupable,
    valueFormatter: (params) => (params.value ? (params.value as string) : ''),
    ...ALIGN_COLUMNS,
  };
};
